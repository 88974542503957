import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const moduleAudio = {
  namespaced: true,
  state: {
    isAudioPlaying: false,
    isAudioPaused: false,
    audioFileURLs: [],
    showController: false,
    catalogIdToPlayAudio: null,
    audioElement: null,
    audioPlayHistory: [],
  },
  mutations: {
    setAudioPlaying(state, playing) {
      state.isAudioPlaying = playing;
    },
    setAudioPaused(state, paused) {
      state.isAudioPaused = paused
    },
    setAudioFileURLs(state, audioFileURLs) {
      state.audioFileURLs.push({catalogId: audioFileURLs.id, url: audioFileURLs.url})
    },
    setShowController(state, show) {
      state.showController = show;
    },
    setCatalogIdToPlayAudio(state, catalogId) {
      state.catalogIdToPlayAudio = catalogId;
    },
    setAudioElement(state, audioElement) {
      state.audioElement = audioElement;
    },
    setAudioPlayHistory(state, audioPlayHistory) {
      const index = state.audioPlayHistory.findIndex(item => item.vsCatalogID === audioPlayHistory.vsCatalogID);
      if (index !== -1) {
        state.audioPlayHistory.splice(index, 1);
      }
      if (state.audioPlayHistory.length >= 10) {
        state.audioPlayHistory.shift();
      }
      state.audioPlayHistory.push(audioPlayHistory);
    },
  },
  actions: {
    playAudio({ commit, state, getters }) {
      if(state.audioElement) {
        // 同じカタログIDの音声を再生中の場合は続きから再生
        if(!state.isAudioPaused) {
          state.audioElement.src = getters.getAudioURL;
        }
        // イベントリスナーを設定
        state.audioElement.addEventListener('play', () => {
          commit('setAudioPaused', false);
          commit('setAudioPlaying', true);
        });
        state.audioElement.addEventListener('pause', () => {
          commit('setAudioPaused', true);
          commit('setAudioPlaying', false);
        });
        state.audioElement.addEventListener('ended', () => {
          commit('setAudioPaused', true);
          commit('setAudioPlaying', false);
        });
        state.audioElement.play();
        commit('setAudioPaused', false);
        commit('setAudioPlaying', true);
      }
    },
    async pauseAudio({ commit, state }) {
      state.audioElement.pause();
      commit('setAudioPaused', true);
      commit('setAudioPlaying', false);
    },
    pushToAudioPlayHistory({ commit }, payload) {
      commit('setAudioPlayHistory', payload);
    }
  },
  getters: {
    getAudioURL: (state) => {
      if(!state.catalogIdToPlayAudio) return null;
      // カタログIDに対応する音声ファイルのURLを返す
      const result = state.audioPlayHistory.find(audio => audio.vsCatalogID === state.catalogIdToPlayAudio);
      return result ? result.audioURL : null;
    },
    getAudioItem: (state) => {
      if(!state.catalogIdToPlayAudio) return null;
      // カタログIDに対応する音声ファイルのURLを返す
      return state.audioPlayHistory.find(audio => audio.vsCatalogID === state.catalogIdToPlayAudio);
    },
    getPreviousAudioItem: (state) => {
      const index = state.audioPlayHistory.findIndex(audio => audio.vsCatalogID === state.catalogIdToPlayAudio);
      if(index === 0 || !state.audioPlayHistory[index - 1] ) return null;
      return state.audioPlayHistory[index - 1];
    },
    getNextAudioItem: (state) => {
      const index = state.audioPlayHistory.findIndex(audio => audio.vsCatalogID === state.catalogIdToPlayAudio);
      if(index === state.audioPlayHistory.length - 1 || !state.audioPlayHistory[index + 1]) return null;
      return state.audioPlayHistory[index + 1];
    },
    getShowController: (state) => {
      return state.showController;
    },
    getCatalogIdToPlayAudio: (state) => {
      return state.catalogIdToPlayAudio;
    },
    getAudioPlaying: (state) => {
      return state.isAudioPlaying;
    },
    getAudioPaused: (state) => {
      return state.isAudioPaused;
    },
    getAudioElement: (state) => {
      return state.audioElement;
    },
    getPlayListLength: (state) => {
      return state.audioPlayHistory.length;
    },
    getCurrentAudioIndex: (state) => {
      return state.audioPlayHistory.findIndex(audio => audio.vsCatalogID === state.catalogIdToPlayAudio);
    },
  },
}

export {moduleAudio};